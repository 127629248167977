<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">Tambah Kasbon Karyawan</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-kasbon"
                      >Kasbon Karyawan
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Tambah
                  </li>
                </ol>
              </nav>
            </div>
            <div>
              <button :disabled="isSubmit" class="btn btn-save">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
            </div>
          </div>

          <div class="card info-detail">
            <div class="row">
              <div class="col-12">
                <div class="title">Kasbon Karyawan</div>
                <div class="sub-title">Tambah informasi kasbon karyawan</div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Tanggal :</label>
                  <Datepicker
                    :autoApply="true"
                    :closeOnScroll="true"
                    v-model="formData.tanggal"
                    placeholder="Tanggal"
                    :format="format"
                    :enableTimePicker="false"
                    locale="id-ID"
                    selectText="Pilih"
                    cancelText="Batal"
                    :class="{ 'is-invalid': formError && formError.tanggal }"
                    @update:modelValue="changeDate($event, 'tanggal')"
                  ></Datepicker>
                  <div class="form-error" v-if="formError && formError.tanggal">
                    {{ formError.tanggal }}
                  </div>
                </div>

                <div class="form-group">
                  <label>Karyawan :</label>
                  <Select2
                    v-model="formData.id_karyawan"
                    :options="optionKaryawan"
                    placeholder="Pilih Karyawan"
                    @change="formChange('id_karyawan')"
                    @select="formChange('id_karyawan')"
                    :class="{
                      'is-invalid': formError && formError.id_karyawan,
                    }"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.id_karyawan"
                  >
                    {{ formError.id_karyawan }}
                  </div>
                </div>

                <div class="form-group">
                  <label>Tenor :</label>
                  <div class="input-group">
                    <input
                      type="text"
                      placeholder="Masukan tenor"
                      v-maska="'#############'"
                      v-model="formData.tenor"
                      :class="{ 'is-invalid': formError && formError.tenor }"
                      class="form-control text-right"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">X Cicilan</span>
                    </div>
                  </div>
                  <div class="form-error" v-if="formError && formError.tenor">
                    {{ formError.tenor }}
                  </div>
                </div>
                <div class="form-group">
                  <label>Apakah cicilan dipotong dari gaji?</label>
                  <div class="row">
                    <div class="col-12 mt-1">
                      <label>
                        <input
                          type="radio"
                          @change="formChange('payroll_cut')"
                          v-model="formData.payroll_cut"
                          value="ya"
                        />
                        <div class="d-flex align-items-center">
                          <span style="color: #263238; font-size: 12px"
                            >Ya</span
                          >
                        </div>
                      </label>
                      <label class="ml-2">
                        <input
                          type="radio"
                          @change="formChange('payroll_cut')"
                          v-model="formData.payroll_cut"
                          value="tidak"
                        />
                        <div class="d-flex align-items-center">
                          <span style="color: #263238; font-size: 12px"
                            >Tidak</span
                          >
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label class="" for="inlineFormInputGroupUsername"
                    >Nilai :</label
                  >
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text">Rp</div>
                    </div>
                    <input
                      type="text"
                      class="form-control text-right"
                      id="inlineFormInputGroupUsername"
                      placeholder="Masukan Nilai Kasbon"
                      v-model.lazy="formData.nilai"
                      v-money="configMoney"
                      :class="{ 'is-invalid': formError && formError.nilai }"
                    />
                  </div>
                  <div class="form-error" v-if="formError && formError.nilai">
                    {{ formError.nilai }}
                  </div>
                </div>

                <div class="form-group">
                  <label>Akun Beban</label>
                  <Select2
                    v-model="formData.beban_akun"
                    :options="OptionAccountEstimated"
                    placeholder="Pilih Akun Perkiraan"
                    :settings="{ templateResult: formatState }"
                    @change="formChange('beban_akun')"
                    @select="formChange('beban_akun')"
                    :class="{ 'is-invalid': formError && formError.beban_akun }"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.beban_akun"
                  >
                    {{ formError.beban_akun }}
                  </div>
                </div>

                <div class="form-group">
                  <label>Kas & Bank :</label>
                  <Select2
                    v-model="formData.akun_kas"
                    :options="OptionAccountKas"
                    placeholder="Pilih Akun Perkiraan"
                    :settings="{ templateResult: formatState }"
                    @change="formChange('akun_kas')"
                    @select="formChange('akun_kas')"
                    :class="{ 'is-invalid': formError && formError.akun_kas }"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.akun_kas"
                  >
                    {{ formError.akun_kas }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";
import Datepicker from "vue3-date-time-picker";
import { ref } from "vue";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";
import { get_KaryawanList } from "../../../actions/karyawan";
import { checkRules, cksClient, showAlert } from "../../../helper";

import { maska } from "maska";
import { VMoney } from "v-money";
import { get_AkunDefault, get_AkunList } from "../../../actions/akun_perkiraan";
import { post_KasbonSave } from "../../../actions/kasbon";
import moment from "moment";
import "moment/locale/id";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    Datepicker,
    // TableLite
  },

  directives: {
    maska,
    money: VMoney,
  },

  data() {
    return {
      OptionAccountEstimated: [],
      OptionAccountKas: [],
      optionKaryawan: [],
      id_company: cksClient().get("_account").id_company,
      isSubmit: false,
      configMoney: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false /* doesn't work with directive */,
      },
      formData: {
        no_kasbon: "",
        id_company: cksClient().get("_account").id_company,
        tanggal: "",
        id_karyawan: "",
        tenor: "",
        nilai: "",
        beban_akun: "",
        akun_kas: "",
        status: "aktif",
        status_pembayaran: "unpaid",
        no_jurnal: "",
        payroll_cut: "ya",
      },
      formError: [],
      rules: {
        tanggal: {
          required: true,
        },
        id_karyawan: {
          required: true,
        },
        tenor: {
          required: true,
        },
        nilai: {
          required: true,
          min: 1,
        },
        beban_akun: {
          required: true,
        },
        akun_kas: {
          required: true,
        },
        payroll_cut: {
          required: true,
        }
      },
    };
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },
  created() {
    this.getDefaultAkun();
    this.getAkun(1);
    this.getAkun(1, true);
    this.getKaryawan();
  },
  methods: {
    //MASTER
    getDefaultAkun() {
      var name_id = "akun_kasbon_karyawan";
      get_AkunDefault(
        {
          id_company: this.id_company,
          name_id: name_id,
        },
        (res) => {
          var list = res.list;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              if (key == 0) {
                this.formData.beban_akun = element;
              }
            }
          }
        }
      );
    },
    getKaryawan() {
      get_KaryawanList(
        {
          id_company: this.id_company,
          search: "aktif",
          with_freelance: "ya"
        },
        (res) => {
          const { list } = res;
          this.optionKaryawan = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionKaryawan.push({
                id: element.id,
                text: element.nama,
              });
            }
          }
        }
      );
    },
    getAkun(tipe = "", not = false) {
      get_AkunList(
        {
          id_company: this.id_company,
          tipe: tipe,
          not_type: not,
        },
        (res) => {
          var data = [];
          const { list } = res;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              data.push({
                id: element.id,
                text: `${element.nama}`,
                no: element.id,
                type: element.tipe,
                sub_level: parseInt(element.sub_level),
                disabled:
                  parseInt(element.max_subLevel) === parseInt(element.sub_level)
                    ? false
                    : true,
              });
            }
          }
          if (tipe == "1" && !not) {
            this.OptionAccountKas = data;
          } else {
            this.OptionAccountEstimated = data;
          }
        }
      );
    },
    changeDate(data, key) {
      this.formData[key] = moment(data).format("YYYY-MM-DD");
      this.formChange(key);
    },
    async formChange(val) {
      if (Object.hasOwnProperty.call(this.rules, val)) {
        this.rules[val].changed = true;
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
    },
    //MASTER
    formatState(state) {
      if (!state.id) {
        return state.text;
      }
      var padding =
        state.sub_level == 2 ? "pl-4" : state.sub_level == 1 ? "pl-3" : "";
      var $state = $(
        '<div class="' +
          padding +
          '">' +
          state.text +
          '<div class="noEstimated" style="font-size: 12px;color: #898989; display:flex; justify-content: space-between;">' +
          state.no +
          "<div>" +
          state.type +
          "</div></div></div>"
      );
      return $state;
    },

    postData() {
      this.isSubmit = true;
      post_KasbonSave(
        this.formData,
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: "Data kasbon berhasil dicatat",
            onSubmit: () => {
              this.$router.push({
                name: "DataEmployeeCash",
              });
            },
          });
        },
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
    async onSubmit() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      }
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
.btn-lampiran {
  background-color: #f9ffd7;
  color: #b9b201;
  font-weight: 400;
}

.card-detail-invoice {
  background: linear-gradient(103.2deg, #6ec0fe 0%, #4df3dd 100%);
  border-radius: 5px;
  color: #fff;
  padding: 10px 20px;
  line-height: 30px;
}

.btn-add-payment {
  background-color: #d7ffea;
  color: #3cb778;
  padding: 5px 10px;
  font-weight: 500;
  margin-right: 15px;
}

.btn-print-invoice {
  background-color: #f9ffd7;
  color: #b9b201;
  padding: 5px 10px;
  font-weight: 500;
}

.address-company {
  font-size: 12px;
  color: #263238;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.noEstimated {
  font-size: 12px;
  color: #898989;
}

.form-label {
  max-width: 134px;
  width: 200px;
}

.card-total {
  display: flex;
}

.card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
}

.payment-invoice {
  padding: 0px 46px;
}

.sub-total {
  margin-right: 50px;
  font-size: 14px;
}

.amount {
  font-size: 14px;
}

.name-company {
  font-size: 18px;
  font-weight: 500;
}

.detail-company .value {
  color: #263238;
  font-size: 16px;
  font-weight: 400;
}

.detail-company .text {
  color: #cdcdcd;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.title-invoice {
  font-weight: 700;
  font-size: 50px;
  background: linear-gradient(102.18deg, #6ec0fe -17.12%, #61efe7 95.5%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.total-payment {
  font-size: 24px;
  font-weight: 600;
  margin-top: 10px;
}

.status-invoice-paid {
  background: #d7ffea;
  color: #3cb778;
  font-size: 20px;
  font-weight: 500;
  padding: 10px 50px;
  border: none;
  cursor: default;
  border-radius: 5px;
}

.btn-export {
  background: #e4efff;
  color: #68caf8;
  font-weight: 400;
  font-size: 16px;
  padding: 10px 10px;
  border-radius: 5px;
  border: none;
  margin-right: 15px;
}

.modal .btn-print-invoice {
  background: #f9ffd7;
  color: #b9b201;
  font-weight: 400;
  font-size: 16px;
  padding: 10px 23px;
  border-radius: 5px;
  border: none;
}

.btn-invoice {
  background: #fff3c7;
  color: #f8ce3a;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 15px;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.info-detail .title {
  font-size: 18px;
  font-weight: 500;
}

.info-detail .sub-title {
  color: #c6c6c6;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}
</style>
